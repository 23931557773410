import React from 'react'

export default function Loader(props) {
    if (props.renderLoader)
        return (
            <div className={'loader-container' + (props.isLoaded ? ' no-bg' : '') + (props.fadeLogoOut ? ' fade-out' : '')}>
                <div className='container'>
                    <div className='loader' />
                    <div className='progress'>{parseInt(props.progress) || 0} %</div>
                </div>
            </div>
        )
}
