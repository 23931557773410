import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { applyProps, useFrame, useLoader, useThree } from '@react-three/fiber'
import { TransformControls, useGLTF } from '@react-three/drei'
import React from 'react';
import * as THREE from 'three'
import { FlakesTexture } from 'three-stdlib'
import TWEEN from '@tweenjs/tween.js';
import CarouselModel from './CarouselModel';

export default function LoadModel(props) {
    let { path, castShadow, selectedTarget, fromSection, service } = props;
    const { scene, nodes, materials, animations } = useGLTF(path);
    const mixer = useRef(new THREE.AnimationMixer(scene));
    let actions = useRef([])

    const transformControlsRef = useRef();
    const ref = useRef();
    const headRef = useRef();
    const target = useRef(new THREE.Vector3());
    const modelRef = useRef();
    const isFadedOut = useRef(false);
    const [mouse, setMouse] = useState({ x: 0, y: 0 });

    // Update mouse position
    const onMouseMove = (event) => {
        setMouse({
            x: (event.clientX / window.innerWidth) * 2 - 1,
            y: -(event.clientY / window.innerHeight) * 2 + 1,
        });
    };

    useEffect(() => {
        scene.rotation.order = 'XYZ'

        if (castShadow)
            scene.traverse(function (object) {

                if (object.isMesh) {
                    if (object.name === 'Cube') {
                        object.receiveShadow = true;
                    } else {
                        object.receiveShadow = true;
                        object.castShadow = true;
                    }
                }
            });

        if (props.optimize)
            scene.traverse((child) => {
                if (child.isMesh) {
                    let materialProps = {
                        color: child.material?.color,
                        map: child.material?.map,
                        lightMap: child.material?.lightMap,
                        lightMapIntensity: child.material?.lightMapIntensity,
                        aoMap: child.material?.aoMap,
                        aoMapIntensity: child.material?.aoMapIntensity,
                        emissive: child.material?.emissive,
                        emissiveIntensity: child.material?.emissiveIntensity,
                        emissiveMap: child.material?.emissiveMap,
                        bumpMap: child.material?.bumpMap,
                        bumpScale: child.material?.bumpScale,
                        normalMap: child.material?.normalMap,
                        normalMapType: child.material?.normalMapType,
                        normalScale: child.material?.normalScale,
                        displacementMap: child.material?.displacementMap,
                        displacementScale: child.material?.displacementScale,
                        displacementBias: child.material?.displacementBias,
                        roughnessMap: child.material?.roughnessMap,
                        roughness: child.material?.roughness,
                        metalnessMap: child.material?.metalnessMap,
                        metalness: child.material?.metalness,
                        alphaMap: child.material?.alphaMap,
                        envMap: child.material?.envMap,
                        envMapIntensity: child.material?.envMapIntensity,
                        refractionRatio: child.material?.refractionRatio,
                        wireframe: child.material?.wireframe,
                        wireframeLinewidth: child.material?.wireframeLinewidth,
                        skinning: child.material?.skinning,
                        morphTargets: child.material?.morphTargets,
                        morphNormals: child.material?.morphNormals,
                        ...checkapplyEmissive(child.material, 'Emission (1)', 10),
                        ...checkapplyEmissive(child.material, 'Emission (1).001', 8, '#ed8f27'),
                    };

                    materialProps = Object.fromEntries(
                        Object.entries(materialProps).filter(([_, v]) => v !== undefined)
                    );

                    child.material = new THREE.MeshStandardMaterial(materialProps);

                    child.geometry.computeVertexNormals();
                    child.material.map && child.material.map.dispose();
                }
            });


        headRef.current = nodes['Head']

        setMaterials()

    }, [scene, nodes, materials])

    const setMaterials = () => {
        if (materials['Sand.001']) {
            materials['Sand.001'].normalMap = new THREE.CanvasTexture(new FlakesTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping)
            materials['Sand.001'].normalMap.repeat.set(4000, 4000)
            materials['Sand.001'].normalScale.set(10, 10)
        }

        if (materials['emissive']) {
            applyProps(materials['emissive'], { toneMapped: false, emissiveIntensity: 8, emissive: '#ed8f27' })
            LightTwinkle('emissive')
        }

        if (materials['Emission']) {
            applyProps(materials['Emission'], { toneMapped: false, emissiveIntensity: 10 })
        }

        if (materials['Emission (1)']) {
            applyProps(materials['Emission (1)'], { toneMapped: false, emissiveIntensity: 10 })
        }

        if (materials['EmissionDT']) {
            applyProps(materials['EmissionDT'], { toneMapped: false, emissiveIntensity: 10 })
        }

        if (materials['Emission (1).001']) {
            applyProps(materials['Emission (1).001'], { toneMapped: false, emissiveIntensity: 8, emissive: '#ed8f27' })
        }

        if (materials['Gold.003']) {
            materials['Gold.003'].color.set('yellow')
            materials['Gold.003'].roughness = 0
            materials['Gold.003'].metalness = 1
            materials['Gold.003'].normalMap = new THREE.CanvasTexture(new FlakesTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping)
            materials['Gold.003'].normalMap.repeat.set(40, 40)
            materials['Gold.003'].normalScale.set(0.1, 0.1);
            materials['Gold.003'].needsUpdate = true;
        }

        if (materials['Emission2']) {
            applyProps(materials['Emission2'], { toneMapped: false, emissiveIntensity: 10 })
        }

        if (materials['MetaverseEmission']) {
            applyProps(materials['MetaverseEmission'], { toneMapped: false, emissiveIntensity: 3, emissive: '#ed8f27' })
        }

        if (service?.type === 'DT') {
            if (materials['PaletteMaterial003']) {
                applyProps(materials['PaletteMaterial003'], { toneMapped: false, emissiveIntensity: 20 })
            }

            if (materials['PaletteMaterial002']) {
                applyProps(materials['PaletteMaterial002'], { toneMapped: false, emissiveIntensity: 10 })
            }

            LightTwinkle('PaletteMaterial003')
        }

        if (service?.type === 'VR')
            LightTwinkle('PaletteMaterial002')

        if (service?.type === 'Simulations') {
            materials['PaletteMaterial001'] && applyProps(materials['PaletteMaterial001'], { toneMapped: false, emissiveIntensity: 5, emissive: '#ed8f27' })
            materials['PaletteMaterial002'] && applyProps(materials['PaletteMaterial002'], { toneMapped: false, emissiveIntensity: 5, emissive: '#ed8f27' })
        }

        if (service?.type === 'REALESTATE')
            materials['PaletteMaterial003'] && applyProps(materials['PaletteMaterial003'], { toneMapped: false, emissiveIntensity: 14, emissive: '#00FFFF' })
    }

    const checkapplyEmissive = (material, name, intensity, color = '#ed8f27') => {
        if (material?.name === name) {
            return {
                toneMapped: false,
                emissiveIntensity: intensity,
                emissive: color,
                emissiveMap: null,
            }
        } else {
            return {}
        }
    }

    useEffect(() => {
        if (props.isMovable)
            transformControlsRef.current.addEventListener("dragging-changed", () => {
                console.log([transformControlsRef.current.object.position?.x, transformControlsRef.current.object.position?.y, transformControlsRef.current.object.position?.z])
            })

        window.addEventListener('mousemove', onMouseMove);
        return () => window.removeEventListener('mousemove', onMouseMove);

    }, [])

    const fadeInOut = (isFadeOut) => {
        return
        if (props.path === '/models/realestate.glb') return
        if (isFadedOut.current && isFadeOut) return;

        let target = {
            opacity: isFadeOut ? 0 : 1
        }

        let initial = {
            opacity: !isFadeOut ? 0 : 1
        }

        let tween = new TWEEN.Tween(initial).to(target, 1500).delay(0).easing(TWEEN.Easing.Cubic.InOut).onUpdate(() => {
            scene.traverse(child => {
                if (child.isMesh) {
                    child.material.transparent = true;

                    if (child?.material?.name === 'Hologram-lights') {
                        child.material.opacity = .10;
                    } else {
                        child.material.opacity = initial.opacity;
                    }
                }
            });
        }).onComplete(() => {
            isFadedOut.current = isFadeOut
        }).start();
    }

    useEffect(() => {
        mixer.current = new THREE.AnimationMixer(scene)
        animations.forEach((clip, index) => {
            const action = mixer.current.clipAction(clip)
            if (clip?.name === "Scene")
                return;

            if (props.isHorse) {
                if (index === 13)
                    actions.current.push(action)
                return
            } else {
                actions.current.push(action)
            }

           action.play()
        })

        props.isKnight && (mixer.current.timeScale = 3)
        return () => animations.forEach((clip) => mixer.current.uncacheClip(clip))
    }, [scene, animations])

    useEffect(() => {
        if (fromSection)
            fadeInOut(selectedTarget?.path !== path)
    }, [selectedTarget])

    useFrame((state, delta) => {
        if (mixer.current)
            mixer.current.update(delta);

        if (headRef.current && (selectedTarget?.isHome || props.isStarted)) {
            const targetY = THREE.MathUtils.clamp(-mouse.x * Math.PI / 2, -Math.PI / 6, Math.PI / 6);
            const targetX = THREE.MathUtils.clamp(-mouse.y * 0.3, -0.2, 0.2);
            headRef.current.rotation.y = THREE.MathUtils.lerp(headRef.current.rotation.y, targetY, 0.05);
            //headRef.current.rotation.x = THREE.MathUtils.lerp(headRef.current.rotation.x, targetX, 0.05);
        }
    });

    const LightTwinkle = (materialName = 'PaletteMaterial002') => {
        if (materials[materialName]) {
            applyProps(materials[materialName], { toneMapped: false, emissive: '#ed8f27' })
            twinkleAnimation(materialName, 20, 8)
        }
    }

    const twinkleAnimation = (materialName, initialIntensity, targetIntensity) => {
        let initial = {
            intensity: initialIntensity
        }, target = {
            intensity: targetIntensity
        }
        let tween = new TWEEN.Tween(initial).to(target, 1000).delay(0).easing(TWEEN.Easing.Cubic.InOut).onUpdate(() => {
            if (materials[materialName]) {
                applyProps(materials[materialName], { emissiveIntensity: initial?.intensity })
            }
        }).onComplete(() => {
            twinkleAnimation(materialName, targetIntensity, initialIntensity) // swithing values each time
        }).start();
    }

    const renderCarousel = () => {
        if (service?.carousel) {
            return <CarouselModel scale={[1.5, 1.5, 1.5]} position={service?.carousel?.position} rotation={service?.carousel?.rotation} isSelected={selectedTarget?.path === path} />
        }
    }

    actions.current?.length && actions.current.forEach(action => action.play())

    if (props.isMovable) {
        return (
            <TransformControls scale={props.scale} position={props.position} rotation={props.rotation} ref={transformControlsRef}>
                <mesh >
                    <primitive object={scene} />
                </mesh>
            </TransformControls>
        )
    }

    return (
        <>
            <mesh castShadow receiveShadow renderOrder={fromSection ? 1 : 0}>
                <primitive object={scene} {...props}/>
            </mesh>
            {renderCarousel()}
        </>
    )
}
