import React, { useEffect, useState } from 'react'

export default function ExploreMore(props) {
    const [isVisible, setIsVisible] = useState(true);
    const {selectedTarget} = props;

    const renderScroll = () => {
        return (
            <>
                <div className='close' onClick={() => props.setIsExploreOpen(false)}>
                    <i/> Close <p>|</p> <span>Esc</span>
                </div>
                <div class={"scroll-more" + (isVisible ? ' visible' : '')}>
                    <i />
                </div>
            </>
        )
    }

    const handleScroll = (event) => {
        const element = event.target;
        if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
            setIsVisible(false)
        } else if (!isVisible) {
            setIsVisible(true)
        }
    };

    const renderUsecases = () => {
        return selectedTarget?.useCases?.map((useCase) => {
            return (
                <div className='use_case_container'>
                    <div className='use_case'>
                        <div className='image' style={{backgroundImage: `url(${useCase?.image})`}}/>
                        <div className='text_container'>
                            <h4>Use cases | {selectedTarget?.title}</h4>
                            <h3>
                                {useCase?.title}
                                <div />
                            </h3>
                            <p>
                                {useCase?.content}
                            </p>
                            <div className='contact-us' onClick={() => props.setIsContactOpen(true)}>
                                Contact us
                                <i />
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className='explore_more'>
            <div className='exp_overlay' />
            <div className='options'>
                {renderScroll()}
            </div>
            <div className='exp_content_scroll' onScroll={handleScroll}>
                <div className='exp_content'>
                    {renderUsecases()}
                </div>
            </div>
        </div>
    )
}