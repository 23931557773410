import React, { useState, useEffect, useRef } from "react";
import DesertScene from "../components/DesertScene";

export default function LandingPageContainer() {
    const [services, setServices] = useState([])

    const menu = [
        {
            title: 'Home',
            camera: {
                position: [
                    2.164970391932504,
                    3.1237995603919817,
                    -6.99810855158563
                ],
                target: [0, 2.5, 0]
            },
            icon: '/icon.webp',
            isHome: 'Collect Services'
        },
        {
            title3D: {
                value: "AR•VR",
                position: [0, -11, 222]
            },
            path: 'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/VR_compressed.glb',
            type: 'VR',
            position: [
                144, -23.3, 95
            ],
            rotation: [0, -1, 0],
            camera: {
                position: /*[
                    178.36484243328474,
                    -25.148680151273375,
                    112.66478198474023
                ],*/
                [
                    168.80209591067896,
                    -25.647171537734135,
                    107.35401467167578
                ],
                target: [10, 12, 0]
            },
            cameraMobile: {
                position: [
                    165.29655213931773,
                    -23.623691327091713,
                    113.023050744041
                ],
                target: [10, 12, 0]
            },
            carousel: {
                position: [145, -22.5, 95],
                rotation: [0, -Math.PI / 6, 0]
            },
            drone: {
                initialTarget: [165.37042114646457, -26.712636792615776, 106.398871651661],
                secondTarget: [143, -14, 95],
            },
            castShadow: true,
            title: "AR • VR Development",
            subtitle: 'Immersive applications',
            description: `Augmented Reality (AR) and Virtual Reality (VR) technologies are revolutionizing how we interact with digital content and the physical world. AR overlays digital information onto the real world, enhancing our surroundings with immersive experiences, while VR creates entirely simulated environments, transporting users to new realms. These technologies hold immense potential across industries, from gaming and entertainment to education and healthcare, promising to redefine the way we perceive and engage with reality.`,
            image: 'https://wallpapers.com/images/hd/augmented-reality-n6uiaazrd8cqlfxf.jpg',
            icon: 'https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/vr_icon.png',
            useCases: [
                {
                    title: 'AR/VR Virtual showroom',
                    content: `Transform your brand's presence with a VR virtual showroom. Immerse customers in a lifelike, interactive environment where they can explore your products and services up close. Offer personalized experiences and tailored guidance, enhancing customer engagement and satisfaction. Drive sales and strengthen brand loyalty through memorable, immersive interactions. A VR virtual showroom positions your brand at the forefront of innovation, setting you apart in today's competitive market.`,
                    image: '/images/SM1.webp'
                },
                {
                    title: 'VR/AR APP',
                    content: `Elevate your brand with a VR/AR app that redefines customer experiences. Offer interactive, immersive environments that allow customers to visualize products and services in detail. Enhance engagement through gamified content and personalized interactions, boosting satisfaction and loyalty. Provide innovative solutions for remote collaboration and training. A VR/AR app positions your brand at the cutting edge of technology, driving growth and setting you apart in today's competitive market.`,
                    image: '/images/arvr.webp',
                },
                {
                    title: 'VR/AR Training and Simulation',
                    content: `Transform your training and simulation programs with VR/AR technology. Provide immersive, hands-on learning experiences that engage and inspire. Enhance skills development and retention through interactive scenarios tailored to your needs. Simulate real-world challenges safely, improving performance and decision-making. VR/AR training and simulation elevate your brand's training approach, driving efficiency and innovation for a competitive edge in your industry.`,
                    image: '/images/SM2.webp'
                },
                {
                    title: 'Interactive Screens',
                    content: `Enhance your brand's engagement with interactive screens. Create captivating, touch-enabled displays that allow customers to explore products and services in detail. Offer personalized experiences and intuitive navigation, improving satisfaction and loyalty. Utilize data-driven insights to tailor content and refine strategies. Interactive screens modernize your brand's presence, elevating your customer interactions and setting you apart in today's digital landscape.`,
                    image: 'https://images.pexels.com/photos/7743260/pexels-photo-7743260.jpeg?auto=compress&cs=tinysrgb&w=1600'
                },
                {
                    title: 'AR/VR Product Visualization',
                    content: `Enhance customer engagement and understanding with AR/VR product visualization. Allow customers to explore your products in 3D or in their own real-world space, providing a comprehensive view from every angle. Offer personalized, interactive experiences that help customers make informed decisions. This innovative approach boosts satisfaction and sales conversions, setting your brand apart with cutting-edge technology and customer-centric experiences.`,
                    image: '/images/AR_Web_App_Trendspace.jpg'
                },
                {
                    title: 'VR Events and Meetings',
                    content: `Transform your brand's events and meetings with VR technology. Host immersive VR events that captivate and engage attendees, offering interactive presentations and experiences. Conduct VR meetings that bring teams together in realistic virtual environments, fostering collaboration and creativity. Enhance networking opportunities and increase participation. VR events and meetings position your brand as an innovator, providing memorable, efficient experiences that drive success and growth.`,
                    image: '/images/Virtual_meeting.webp'
                }
            ]
        },
        {
            title3D: {
                value: "Games",
                position: [-40, -11, 222],
            },
            path: 'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/manette2.glb',
            type: 'Games',
            position: [219.9278918834303, -21.975546688787436, 233.77598576868],
            rotation: [0, -1, 0],
            isMovable: false,
            isOverlayLeft: true,
            camera: {
                position: /*[
                    231.56251243973776,
                    -21.384525411435334,
                    255.92968521753875
                ],*/
                [
                    226.21335381258413,
                    -21.902796749434266,
                    245.29465070433884
                ],
                target: [10, 12, 0]
            },
            drone: {
                initialTarget: [223.12524917950224, -22.485756839186444,235.48261806796234],
                secondTarget: [219.9278918834303, -9, 233.77598576868],
            },
            castShadow: true,
            title: "Game Development",
            subtitle: '3D gaming experiences',
            description: `Our game development services transform your ideas into captivating, immersive experiences. From concept to launch, our skilled team handles design, coding, and testing for mobile, console, and VR platforms. Partner with us to create your next gaming hit.`,
            image: 'https://4kwallpapers.com/images/wallpapers/playstation-5-3840x2160-15521.jpg',
            icon: 'https://slidzo.s3.eu-central-1.amazonaws.com/desertknight/gamepad2.png',
            useCases: [
                {
                    "title": "Entertainment and Gaming",
                    "content": "Develop captivating games and entertainment experiences that engage and retain audiences. Provide innovative storytelling and gameplay to offer players memorable, immersive adventures. Enhance your brand's reputation in the gaming industry.",
                    "image": '/images/GD_0.webp'
                },
                {
                    "title": "Game Porting",
                    "content": "Port games across different platforms to reach wider audiences and maximize revenue potential. Adapt existing games to run smoothly on various devices, ensuring a seamless gaming experience for players everywhere.",
                    "image": '/images/GD5.webp'
                },
                {
                    "title": "Game Companion App",
                    "content": "Develop a game companion app to enhance player experience and engagement. Offer features such as real-time game updates, interactive maps, and community forums. Provide players with additional content, tips, and social integration to deepen their connection with your game.",
                    "image": '/images/GD10.webp'
                },                
                {
                    "title": "Gamified Learning and Training",
                    "content": "Leverage game development expertise to create engaging educational games for businesses and organizations. Use gamification to make learning fun and effective, fostering better retention and performance.",
                    "image": '/images/GD_2.jpg'
                },
                {
                    "title": "VR/AR Game Development",
                    "content": "Create immersive games using VR and AR technologies, delivering new and exciting experiences to players. Blend reality and imagination to offer groundbreaking games that captivate audiences worldwide.",
                    "image": '/images/GD3.jpg'
                }
            ]
        },
        {
            title3D: {
                value: "Metaverse",
                position: [-120, -11, 210]
            },
            path: 'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/metaverse_compressed2.glb',
            icon: '/icons/metaverse.png',
            type: 'METAVERSE',
            position: [
                -353.7049432427048,
                -12.746951438776868,
                314.75116608541737
            ],
            rotation: [0, -2, 0],
            scale: [1, 1, 1], 
            isOverlayLeft: true,
            camera: {
                position: [
                    -374.8522228908971,
                    -11.359799635252333,
                    326.4344863400752
                ],
                target: [10, 12, 0]
            },
            cameraMobile: {
                position: [
                    -373.6892081879799,
                    -8.836762528551628,
                    327.9648748035376
                ],
                target: [10, 12, 0]
            },
            drone: {
                initialTarget: [
                    -360,
                    -15,
                    320
                ],
                secondTarget: [
                    -353.7049432427048,
                    -5,
                    314.75116608541737
                ],
            },
            castShadow: false,
            title: "Metaverse Solutions",
            description: `Our Metaverse solutions immerse your audience in dynamic, interactive virtual worlds. We specialize in creating engaging 3D environments, social spaces, and digital economies that enhance user experiences and drive innovation. Partner with us to navigate and thrive in the expanding metaverse landscape.`,
            useCases: [
                {
                    title: `Asset Monitoring and Management`,
                    content: `Optimize your brand's operations with asset monitoring and management through digital twin technology. Create virtual replicas of physical assets to enable real-time tracking of performance and health. Detect issues early, schedule predictive maintenance, and streamline processes for maximum efficiency. Gain data-driven insights for strategic decision-making and resource allocation. Asset monitoring and management with digital twins enhances productivity and extends asset lifespan, giving your brand a competitive edge.`,
                    image: '/images/DT1.webp',
                },
                {
                    "title": "Process Simulation and Analysis",
                    "content": "Optimize your operations with process simulation and analysis through digital twin technology. Virtually test different scenarios to identify inefficiencies and opportunities for improvement. Analyze data-driven insights to enhance workflows, reduce costs, and boost productivity. Experiment with changes in a risk-free environment. Process simulation and analysis empower your brand to make informed decisions and innovate effectively.",
                    "image": '/images/DT2.jpg'
                },
                {
                    "title": "Urban Planning and Development",
                    "content": "Leverage digital twin technology for urban planning and development. Create virtual models of cities and infrastructures to simulate and optimize growth and development strategies. Test scenarios for sustainability, traffic flow, and resource management. Digital twins provide insights for data-driven decision-making, enhancing your brand's impact on modern urban landscapes.",
                    "image": '/images/Real_estate_05.webp'
                },
                {
                    "title": "Energy and Resource Management",
                    "content": "Enhance your brand's sustainability with energy and resource management using digital twin technology. Monitor and optimize energy usage and resource allocation in real-time. Identify opportunities for savings and efficiency improvements. Utilize data-driven insights for better decision-making and strategic planning. Digital twins empower your brand to achieve operational excellence and environmental responsibility.",
                    "image": '/images/DT4.webp'
                }
            ],
            children : [
                {
                    title3D: {
                        value: "Digital Twin",
                        position: [-80, -11, 210],
                    },
                    path: 'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/DT_sword-v2.glb',
                    icon: '/icons/DT.png',
                    type: 'DT',
                    position: [
                        -59.30835961616909,
                        -19,
                        241.83294077069434
                    ],
                    rotation: [0, 0, 0],
                    scale: [.4, .4, .4], 
                    camera: {
                        position: [
                            -65.46548142556846,
                            -18.648906591669714,
                            298.9041068068676
                        ],
                        target: [10, 12, 0],
                    },
                    cameraMobile: {
                        position: [
                            -76.23650837748744,
                            -13.62493065759448,
                            296.45351571489596
                        ],
                        target: [10, 12, 0],
                    },
                    drone: {
                        initialTarget: [-51.58654446626272, -15.94628887702711, 241.7569387712944],
                        secondTarget: [-61, -8, 248],
                    },
                    castShadow: false,
                    title: "Digital Twin",
                    description: `Our Digital Twin services provide accurate virtual replicas of physical assets, processes, and systems. Leveraging advanced simulations and real-time data, we enable you to optimize performance, predict outcomes, and enhance decision-making. Unlock the full potential of your digital transformation!`,
                    useCases: [
                        {
                            title: `Asset Monitoring and Management`,
                            content: `Optimize your brand's operations with asset monitoring and management through digital twin technology. Create virtual replicas of physical assets to enable real-time tracking of performance and health. Detect issues early, schedule predictive maintenance, and streamline processes for maximum efficiency. Gain data-driven insights for strategic decision-making and resource allocation. Asset monitoring and management with digital twins enhances productivity and extends asset lifespan, giving your brand a competitive edge.`,
                            image: '/images/DT1.webp',
                        },
                        {
                            "title": "Process Simulation and Analysis",
                            "content": "Optimize your operations with process simulation and analysis through digital twin technology. Virtually test different scenarios to identify inefficiencies and opportunities for improvement. Analyze data-driven insights to enhance workflows, reduce costs, and boost productivity. Experiment with changes in a risk-free environment. Process simulation and analysis empower your brand to make informed decisions and innovate effectively.",
                            "image": '/images/DT2.jpg'
                        },
                        {
                            "title": "Urban Planning and Development",
                            "content": "Leverage digital twin technology for urban planning and development. Create virtual models of cities and infrastructures to simulate and optimize growth and development strategies. Test scenarios for sustainability, traffic flow, and resource management. Digital twins provide insights for data-driven decision-making, enhancing your brand's impact on modern urban landscapes.",
                            "image": '/images/Real_estate_05.webp'
                        },
                        {
                            "title": "Energy and Resource Management",
                            "content": "Enhance your brand's sustainability with energy and resource management using digital twin technology. Monitor and optimize energy usage and resource allocation in real-time. Identify opportunities for savings and efficiency improvements. Utilize data-driven insights for better decision-making and strategic planning. Digital twins empower your brand to achieve operational excellence and environmental responsibility.",
                            "image": '/images/DT4.webp'
                        }
                    ]
                },
                {
                    title3D: {
                        value: "Simulations",
                        position: [-158, -11, 210]
                    },
                    path: 'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/simulation.glb',
                    icon: '/icons/simulation.webp',
                    type: 'Simulations',
                    position: [ -358.6770068494017, -16, 158.7957080426257 ],
                    rotation: [0, -2, 0],
                    scale: [.4, .4, .4], 
                    camera: {
                        position: [ -389.7734213618591, -15.51428640221964, 178.6004860223756 ],
                        target: [10, 12, 0]
                    },
                    cameraMobile: {
                        position: [
                            -391.9582903623826,
                            -10.763407059346033,
                            174.3138494182827
                        ],
                        target: [10, 12, 0]
                    },
                    drone: {
                        initialTarget: [ -358.6770068494017, -15, 158.7957080426257 ],
                        secondTarget: [ -358.6770068494017, -12, 158.7957080426257 ],
                    },
                    castShadow: false,
                    title: "Simulations",
                    description: `Our Metaverse solutions immerse your audience in dynamic, interactive virtual worlds. We specialize in creating engaging 3D environments, social spaces, and digital economies that enhance user experiences and drive innovation. Partner with us to navigate and thrive in the expanding metaverse landscape.`,
                    useCases: [
                        {
                            "title": "Virtual Car Showrooms",
                            "content": "Offer immersive virtual showrooms where customers can explore and customize cars in stunning detail. Provide interactive experiences that allow buyers to visualize different models and features, enhancing their purchasing journey and driving sales.",
                            "image": '/images/SM1.webp'
                        },
                        {
                            "title": "Industry 4.0 Simulations",
                            "content": "Simulate Industry 4.0 processes and technologies to optimize manufacturing and production operations. Test automation, robotics, and IoT solutions in a virtual environment to improve efficiency, reduce costs, and accelerate innovation.",
                            "image": '/images/SM2.webp'
                        },
                        {
                            "title": "Educational Simulations",
                            "content": "Develop educational simulations to enhance learning experiences for students of all ages. Create interactive environments that simulate real-world scenarios, providing hands-on learning opportunities in subjects such as science, history, and geography.",
                            "image": '/images/GD_2.jpg'
                        },
                        {
                            "title": "Virtual Real Estate Planning",
                            "content": "Create virtual maquettes to visualize real estate developments and urban planning projects. Offer immersive experiences that allow stakeholders to explore proposed designs in detail, facilitating better decision-making and community engagement.",
                            "image": '/images/SM4.webp'
                        }
                    ]
                },
                {
                    title3D: {
                        value: "Real Estate",
                        position: [-200, -11, 210]
                    },
                    path: 'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/realestate.glb',
                    icon: '/icons/realestate.png',
                    type: 'REALESTATE',
                    position: [
                        -359.2260894692561,
                        -13.7,
                        -129.47645558114854
                    ],
                    rotation: [0, 2, 0],
                    scale: [.07, .07, .07],
                    camera: {
                        position: [
                            -405.10356148665716,
                            -11.388018170999075,
                            -140.14959800485715
                        ],
                        target: [10, 12, 0]
                    },
                    cameraMobile: {
                        position: [
                            -409.1909677134952,
                            -5.086012734871556,
                            -154.24806015365147
                        ],
                        target: [10, 12, 0]
                    },
                    drone: {
                        initialTarget: [ -358.6770068494017, -15, 158.7957080426257 ],
                        secondTarget: [ -358.6770068494017, -12, 158.7957080426257 ],
                    },
                    castShadow: false,
                    title: "Real Estate Solutions",
                    description: `Our real estate solutions streamline property management, enhance customer engagement, and drive sales. We provide cutting-edge tools and technologies for property listings, virtual tours, and client management, tailored to meet the needs of real estate professionals. Transform and elevate your real estate business..`,
                    useCases: [
                        {
                            "title": "Virtual Property Tours",
                            "content": "Offer virtual property tours that allow potential buyers to explore real estate remotely. Provide interactive, immersive experiences that showcase properties in detail. Virtual tours expand your brand's reach and improve customer engagement.",
                            "image": '/images/Real_estate1.jpg'
                        },
                        {
                            "title": "Digital Real-Estate Showrooms",
                            "content": "Create digital showrooms for real estate agencies to present properties and developments in an engaging, interactive manner. Showcase features and amenities to enhance customer experience and drive sales.",
                            "image": '/images/real_estate2.webp'
                        },
                        {
                            "title": "Virtual Real Estate Planning",
                            "content": "Create virtual maquettes to visualize real estate developments and urban planning projects. Offer immersive experiences that allow stakeholders to explore proposed designs in detail, facilitating better decision-making and community engagement.",
                            "image": '/images/SM4.webp'
                        },
                        {
                            "title": "Smart Building Management",
                            "content": "Utilize smart building management technology to optimize energy usage and maintenance. Monitor and control building systems in real-time, ensuring efficient operations and sustainability.",
                            "image": '/images/Real_estate_05.webp'
                        },
                        {
                            "title": "Augmented Reality Property Visualization",
                            "content": "Leverage AR to enable potential buyers to visualize property changes and customizations in real time. Enhance the buying experience by providing personalized views and design possibilities.",
                            "image": '/images/real_estate4.jpg'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Contact Us',
            isContact: true
        }
    ];

    useEffect(() => {
        let servicesResult = [];
        if (services?.length) 
            return;

        for (let element of menu) {
            !element?.isContact && servicesResult.push(element);
            if (element?.children?.length) {
                servicesResult.push(...element.children);
            }
        }

        setServices(servicesResult)
    }, [])

    return (
        <DesertScene services={services} menu={menu}/>
    )
}
