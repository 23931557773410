import { useLoader, useThree } from '@react-three/fiber'
import { EffectComposer, SSR, Bloom, LUT, SSAO, GodRays, HueSaturation } from '@react-three/postprocessing'
import { LUTCubeLoader } from 'postprocessing'
import React, { useCallback, useEffect, useState } from 'react';
import { BlendFunction, KernelSize, Resizer } from 'postprocessing'
import * as THREE from 'three'

export function Effects(props) {
    const texture = useLoader(LUTCubeLoader,  'https://slidzo.s3.eu-central-1.amazonaws.com/PostProcess/F-6800-STD.cube');

    useEffect(() => {
        return
        if (texture) {
            console.log('Texture Loaded:', texture);
            texture.wrapS = THREE.ClampToEdgeWrapping;
            texture.wrapT = THREE.ClampToEdgeWrapping;
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            texture.anisotropy = 1;
            texture.flipY = false;

            console.log('Texture Size:', texture.image.width, texture.image.height);
        } else {
            console.warn('Failed to load texture');
        }
    }, [texture]);

    const renderGodrays = () => {
        // if (props.optimizePerformance) return;
        return (
            <>
                {
                    props.godrayRef?.current && props.godrayRef?.current !== null &&
                    <GodRays
                        sun={props.godrayRef.current}
                        blendFunction={BlendFunction.Screen}
                        samples={40}
                        density={0.96}
                        decay={0.9}
                        weight={0.8}
                        exposure={0.6}
                        clampMax={1}
                        width={Resizer.AUTO_SIZE}
                        height={Resizer.AUTO_SIZE}
                        kernelSize={KernelSize.SMALL}
                        blur={true}
                    />
                }

                {
                    props.godrayRef2?.current && props.godrayRef2?.current !== null &&
                    <GodRays
                        sun={props.godrayRef2.current}
                        blendFunction={BlendFunction.Screen}
                        samples={40}
                        density={0.96}
                        decay={0.9}
                        weight={0.8}
                        exposure={0.6}
                        clampMax={1}
                        width={Resizer.AUTO_SIZE}
                        height={Resizer.AUTO_SIZE}
                        kernelSize={KernelSize.SMALL}
                        blur={true}
                    />
                }
                {
                    props.godrayRef3?.current && props.godrayRef3?.current !== null &&
                    <GodRays
                        sun={props.godrayRef3.current}
                        blendFunction={BlendFunction.Screen}
                        samples={40}
                        density={0.96}
                        decay={0.9}
                        weight={0.8}
                        exposure={0.6}
                        clampMax={1}
                        width={Resizer.AUTO_SIZE}
                        height={Resizer.AUTO_SIZE}
                        kernelSize={KernelSize.SMALL}
                        blur={true}
                    />
                }
            </>
        )
    }

    return (
        true && (
            <EffectComposer>
                {renderGodrays()}
                <Bloom luminanceThreshold={0.8} mipmapBlur luminanceSmoothing={0} intensity={props.bloomValue} width={500} height={500} />
                {(!props.isMobile) && texture && <LUT lut={texture}/>}
            </EffectComposer>
        )
    )
}
