import { Float, useGLTF } from '@react-three/drei';
import { applyProps, useFrame, useLoader } from '@react-three/fiber';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSpring, animated, config, easings } from '@react-spring/three'
import * as THREE from 'three'

export default function LoadDrone(props) {
    let {path, selectedTarget, origin} = props;
    const { scene, nodes, materials, animations } = useGLTF(path)
    const mixer = useRef(new THREE.AnimationMixer(scene));
    let actions = useRef([])
    const updatedMaterials = {};

    const droneRef = useRef();
    const getInitialTargetPosition = () => {
        return [
            165.37042114646457,
            -26.712636792615776,
            106.398871651661
        ]
    }
    const [targetPosition, setTargetPosition] = useState(getInitialTargetPosition())
    const [isSecondPosition, setIsSecondPosition] = useState(false)
    const [openLights, setOpenLights] = useState(false)

    const { position } = useSpring({
        position: selectedTarget?.drone ? targetPosition : [origin[0], origin[1], origin[2]],
        config: { duration: isSecondPosition ? 5000 : 7000, easing: easings.easeInOutCubic },
        onRest: () => {
            if (isSecondPosition) {
                setOpenLights(true);
            }

            if (selectedTarget) {
                setTargetPosition(selectedTarget?.drone?.secondTarget);
                setIsSecondPosition(true);
            } 
        }
    });

    useEffect(() => {
        if (selectedTarget?.drone?.initialTarget) {
            setTargetPosition(selectedTarget?.drone?.initialTarget)
            setOpenLights(false);
        }
    }, [selectedTarget])

    useEffect(() => {
        scene.rotation.order = 'XYZ'

        scene.traverse((child) => {
            if (child.isMesh) {
                let oldMaterial = child.material;
        
                let materialProps = {
                    color: child.material?.color,
                    map: child.material?.map,
                    lightMap: child.material?.lightMap,
                    lightMapIntensity: child.material?.lightMapIntensity,
                    aoMap: child.material?.aoMap,
                    aoMapIntensity: child.material?.aoMapIntensity,
                    emissive: child.material?.emissive,
                    emissiveIntensity: child.material?.emissiveIntensity,
                    emissiveMap: child.material?.emissiveMap,
                    normalMap: child.material?.normalMap,
                    normalMapType: child.material?.normalMapType,
                    normalScale: child.material?.normalScale,
                    roughnessMap: child.material?.roughnessMap,
                    roughness: child.material?.roughness,
                    metalnessMap: child.material?.metalnessMap,
                    metalness: child.material?.metalness,
                    alphaMap: child.material?.alphaMap,
                    envMap: child.material?.envMap,
                    envMapIntensity: child.material?.envMapIntensity,
                    opacity: child.material?.opacity,
                    transparent: child.material?.transparent,
                    // ...checkapplyEmissive(child.material, 'PaletteMaterial005', 14),
                    ...checkapplyEmissive(child.material,'PaletteMaterial004', 20, '#66B5FF'),
                    ...checkapplyEmissive(child.material,'PaletteMaterial007', 24, '#FFF694')
                };
        
                materialProps = Object.fromEntries(
                    Object.entries(materialProps).filter(([_, v]) => v !== undefined)
                );
        
                child.material = new THREE.MeshStandardMaterial(materialProps);
        
                child.geometry.computeVertexNormals();
            }
        });
        
        if (materials['M_0_0']) {
            applyProps(materials['M_0_0'], { toneMapped: false, emissiveIntensity: 10 })
        }

        if (materials['emission']) {
            applyProps(materials['emission'], { toneMapped: false, emissiveIntensity: 10 })
        }

    }, [scene, nodes, materials])

    const applyEmissive = (name, intensity, color = '#ed8f27') => {
        if (materials[name]) {
            applyProps(updatedMaterials[name], { toneMapped: false, emissiveIntensity: intensity, emissive: color, emissiveMap: null })
        }
    }

    const checkapplyEmissive = ( material, name, intensity, color = '#ed8f27') => {
        if (material?.name === name) {
            /*material.toneMapped = false;
            material.emissiveIntensity = intensity;
            material.emissive = color;
            material.emissiveMap = null;*/

            return {
                toneMapped: false,
                emissiveIntensity: intensity,
                emissive: color,
                emissiveMap: null,
            }
        } else {
            return {}
        }
    }

    useEffect(() => {
        mixer.current = new THREE.AnimationMixer(scene)
        animations.forEach((clip) => {
          const action = mixer.current.clipAction(clip)
          if (clip?.name === "object_67Action.002") {
            action.timeScale = 4
          }
          action.play()
          actions.current.push(action)
        })
        return () => animations.forEach((clip) => mixer.current.uncacheClip(clip))
      }, [scene, animations])

    useFrame((state, delta) => {
        const time = state.clock.getElapsedTime();
        const amplitude = 0.1;
        const frequency = 1;

        const rotationX = Math.sin(time * frequency) * amplitude;
        const rotationY = Math.cos(time * frequency) * amplitude;

        if (droneRef.current) {
            droneRef.current.rotation.x = rotationX;
            droneRef.current.rotation.y = rotationY;
        }

        mixer.current.update(delta);
    });

    const renderDroneLight = () => {
        return (
            <>
                <mesh ref={props.godrayRef} position={[1, -9.3, 0]}>
                    <coneBufferGeometry args={[10, 25, 25]} />
                    <meshPhongMaterial transparent={true} opacity={openLights && selectedTarget?.title !== "Home" ? .0003 : 0} toneMapped={false} color={"#fad264"} emissive="#fad264" emissiveIntensity={20} depthWrite={false} depthTest={false}/>
                </mesh>
                {
                    (!props.isMobile) && openLights && selectedTarget?.title !== "Home" && 
                    <spotLight position={[0, 1, 0]} intensity={.1} angle={Math.PI} penumbra={0.9} />
                }
            </>
        )
    }

    actions.current?.length && actions.current.forEach(action => action.play())

    return (
        <>
            <Float
                speed={8}
                rotationIntensity={0}
                floatIntensity={1}
                floatingRange={[0, .15]}
            >
                <animated.mesh position={position} ref={droneRef}>
                    <primitive object={scene} {...props} />
                    {renderDroneLight()}
                </animated.mesh>
            </Float>
        </>
    )
}
