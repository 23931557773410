import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react';

export default function FovHandler(props) {
    const {camera} = useThree()
    const fov = useRef(20);

    useEffect(() => {
        checkFov(window.innerWidth)
        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const handleResize = () => {
        checkFov(window.innerWidth);
    }

    const updateFOV = (camera, fovVal) => {
        camera.fov = fovVal;
        camera.updateProjectionMatrix()
        fov.current = fovVal
    }

    const checkFov = (windowSize) => {
        let breakPoint = 900;

        if (windowSize > breakPoint && fov.current !== 20) {
            updateFOV(camera, 20)
        }

        if (windowSize <= breakPoint && fov.current !== 40) {
            updateFOV(camera, 40)
        }
    }

    return null;
}