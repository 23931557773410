import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './index.scss'
import LandingPageContainer from './containers/LandingPageContainer';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LandingPageContainer />} />
            </Routes>
        </BrowserRouter>

    );
}

export default App;