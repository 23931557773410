import React, { Component, useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';

export default function Preload(props) {
    let { path } = props;
    const { scene, nodes, materials, animations } = useGLTF(path);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(false)
        }, 500)
    }, [])

    if (isVisible) {
        return (
            <mesh>
                <primitive object={scene} scale={[.01,.01,.01]}/>
            </mesh>
        )
    } else
        return null
}