import React, { useEffect } from 'react'

export default function MainTitles(props) {
    const {services, selectService} = props;

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.repeat) return;

            var key = event.keyCode;

            switch (key) {
                case 88:
                    handleStart()
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.addEventListener('keydown', handleKeyDown);
        };
    }, [])

    const handleStart = () => {
        if (!services?.length) return;

        selectService(services[1])
    }

    const renderStartMenu = () => {
        return (
            <div className={'start-menu'} onClick={() => handleStart()}>
                <div className='btn'>X</div> <div>START</div>
            </div>
        )
    }

    return (
        <div className='main_titles'>

            <h3>
                Desert <br /><span>Knight</span>
            </h3>
            <h5>
                Welcome to the Metaverse company
            </h5>
            <div className='title-ornament' />
            <div className='title-ornament-top' />
            {renderStartMenu()}
        </div>
    )
}
