import React from 'react'

export default function Header(props) {
    const {selectedTarget, services, selectService, setIsContactOpen, isContactOpen} = props;
    
    const handleSelectService = (event, service) => {
        event.stopPropagation()
        if (service?.title !== selectedTarget?.title && !service?.isContact) {
            return selectService(service)
        }

        if (service?.isContact)
            setIsContactOpen(true)
    }

    const renderElements = (servicesList, isFrom) => {
        return servicesList.map((service) => {
            return (
                <div className={((service?.title === selectedTarget?.title || service?.children?.some(child => child.title === selectedTarget?.title)) ? 'active' : '') + (service?.children?.length ? ' menu' : '')} onClick={(e) => handleSelectService(e, service)}>
                    {service?.title}
                    {
                        service?.children?.length &&
                        <>
                            <i class='bx bx-chevron-down' />
                            <div className='menu-items'>
                                {renderElements(service?.children, true)}
                            </div>
                        </>
                    }
                    {
                        !isFrom && service?.title === selectedTarget?.title && !service?.children?.length &&
                        <div className='active-child'/>
                    }
                </div>
            )
        })
    }

    return (
        <div className='header'>
            <div className='logo' onClick={(e) => handleSelectService(e, services[0])} />
            <div className='header-titles desktop'>
                {renderElements(services)}
            </div>
            {
                !isContactOpen && 
                <div className='header-titles mobile'>
                    <div className='active' onClick={() => setIsContactOpen(true)}>
                        Contact us
                        <div className='active-child'/>
                    </div>
                </div>
            }
        </div>
    )
}
