import React, { useEffect, useState } from 'react'
import MainTitles from './MainTitles'
import Header from './Header'
import Loader from './Loader'
import ExploreMore from './ExploreMore';
import Contact from './Contact';

export default function Overlay(props) {
    const {selectedTarget, services, selectService, menu, setIsExploreOpen, setIsContactOpen, isContactOpen, isExploreOpen} = props;
    let isMounted = false;

    useEffect(() => {
        if (isMounted) return;

        isMounted = true
        document.addEventListener('keydown', (event) => {
            if (event.repeat) return;

            var key = event.keyCode;

            switch (key) {
                case 27:
                    if (isContactOpen) {
                        return setIsContactOpen(false)
                    } else
                        setIsExploreOpen(false)
                    break;
                default:
                    break;
            }
        }, false);
    }, [])

    useEffect(() => {
        setIsExploreOpen(false)
    }, [selectedTarget])
    
    const getNextPrevService = (isNext = false) => {
        const index = services.findIndex(service => service.title === selectedTarget.title);
        if (index !== -1) {
            let newIndex;
            if (isNext) {
                newIndex = (index + 1) % services.length;
            } else {
                newIndex = (index - 1 + services.length) % services.length;
            }
            return services[newIndex];
        }
        return null; 
    }

    const setSelectedTarget = (isNext) => {
        const service = getNextPrevService(isNext);
        selectService(service, true)
    }
    
    const renderSelectedTargetTitles = (service) => {
        return (
            <>
                <div className={'main_titles ' + (service?.isOverlayLeft ? 'left' : 'right')}>
                    <h4>Our Services</h4>
                    <h3 className='service'>{service?.title}</h3>
                    <div className='ornaments-top'/>
                    <p>
                        {service?.description}
                    </p>
                    <div className='ornaments-bottom' />
                </div>
            </>
        )
    }

    const handleTitles = () => {
        if (selectedTarget && selectedTarget?.title !== 'Home') {
            return renderSelectedTargetTitles(selectedTarget)
        } else if (props.overlayTitles || selectedTarget?.title === 'Home') {
            return <MainTitles selectService={selectService} services={services}/>
        }
    }

    const renderServiceName = (service) => {
        return (
            <div className='service-name'>
                <div className='icon-container'>
                   <div className='background' /> 
                    <div className={'icon' + ((service?.isHome || !service) ? ' home' : '')}>
                        <div className='icon-image' style={{backgroundImage: `url(${service?.icon || '/icon.webp'})`}} />
                    </div>
                </div>

                <div className='content-container'>
                    <h3>{(service?.isHome || !service) ? 'Chapter' : 'Service'}</h3>
                    <p>{service?.isHome || service?.title || 'Collect Services'}</p>
                </div>
            </div>
        )
    }

    const renderExploreMore = (service) => {
        if (service && !service?.isHome)
            return (
                <div className='explore-more'>
                    <div className='subtitle'>
                        {'Click here and' || service?.title}
                    </div>
                    <div className='title' onClick={() => setIsExploreOpen(true)}>
                        Explore More
                    </div>
                </div>
            )

        return renderScrollExplore()
    }

    const renderScrollExplore = () => {
        return (
            <div className='scroll-explore'>
                <div>Scroll to Explore</div>
            </div>
        )
    }

    const renderNavigation = (service) => {
        return (
            <div className='navigation'>
                <div className={'element ' + (!service?.isHome ? 'active' : '')} onClick={() => !service?.isHome && setSelectedTarget(false)}>
                    <div className='arrow back ' />
                    <div className='label'>Previous</div>
                </div>
                <div className='separator'>•</div>
                <div className='element active' onClick={() => setSelectedTarget(true)}>
                    <div className='label'>Next</div>
                    <div className='arrow' />
                </div>
            </div>
        )
    }

    const renderHudDetails = () => {
        return (
            <div className='hud-details'>
                {renderServiceName(selectedTarget)}
                {renderExploreMore(selectedTarget)}
                {renderNavigation(selectedTarget)}
            </div>
        )
    }

    const switchExplore = () => {
        if (isContactOpen) {
            return (
                <Contact setIsContactOpen={setIsContactOpen}/>
            )
        } else if (isExploreOpen && selectedTarget?.useCases?.length) {
            return (
                <ExploreMore setIsExploreOpen={setIsExploreOpen} selectedTarget={selectedTarget} setIsContactOpen={setIsContactOpen} isContactOpen={isContactOpen}/>
            )
        }
    }

    return (
        <div className='overlay'>
            <Header
                selectedTarget={selectedTarget}
                selectService={selectService}
                services={menu}
                setIsContactOpen={setIsContactOpen}
                isContactOpen={isContactOpen}
            />
            {handleTitles()}
            <Loader
                renderLoader={props.renderLoader} 
                isLoaded={props.isLoaded} 
                fadeLogoOut={props.fadeLogoOut}
                progress={props.progress}
            />
            {renderHudDetails()}
            {switchExplore()}
        </div>
    )
}
