import { CameraControls, useScroll } from '@react-three/drei'
import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash';

export default function Controls(props) {
    const [isActive, setIsActive] = useState();
    const cameraControlsRef = useRef();
    const { camera } = useThree();
    const { selectedTarget, isMobile, services, selectService, isExploreOpen, isContactOpen, origin, isLoaded } = props;
    const [cameraPosition, setCameraPosition] = useState({
        position: [
            3.5280195290434087,
            4.216953855963872,
            -17.41102546302171
        ],
        target: [0, 2, 0]
    })
    const prevTarget = useRef();
    const lastScroll = useRef(0);
    const currentIndex = useRef(0);
    const isScrolling = useRef(false)
    const touchStartY = useRef()
    const touchEndY = useRef()
    const isMenuOpen = useRef(false)
    const isAdmin = false;
    const isPrevious = useRef(false);

    useEffect(() => {
        cameraControlsRef?.current.addEventListener('controlend', () => {
            if (isAdmin) {
                console.log([camera.position.x, camera.position.y, camera.position.z])
                console.log(cameraControlsRef?.current?.getTarget())
            }
        })

       // setInitialCamera()

        if (!isAdmin) {
            const controls = cameraControlsRef.current;
            controls.mouseButtons.right = null;
            controls.mouseButtons.left = null;
            controls.mouseButtons.wheel = null;
            controls.mouseButtons.middle = null;
            controls.touches.one = null;
            controls.touches.two = null;
            controls.touches.three = null;

            window.addEventListener('wheel', handleWheel);
            window.addEventListener('touchstart', touchStart);
            window.addEventListener('touchmove', touchMove);
            window.addEventListener('touchend', touchEnd);
        }
       
        document.addEventListener('keydown', (event) => {
            if (event.repeat) return;

            var key = event.keyCode;

            switch (key) {
                case 69:
                    setIsActive(true)
                    break;
                case 82:
                    setIsActive(false)
                    break;
                default:
                    break;
            }

        }, false);

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('touchstart', touchStart);
            window.removeEventListener('touchmove', touchMove);
            window.removeEventListener('touchend', touchEnd);
        };

    }, [])

    useEffect(() => {
        isMenuOpen.current = isExploreOpen || isContactOpen
    }, [isExploreOpen, isContactOpen])

    useEffect(() => {
        moveCameraToPosition()
    }, [cameraPosition])

    useEffect(() => {
        if (selectedTarget?.camera) {
            handleCameraTarget()
        }

        if (selectedTarget) {
            const indexCurrent = getServiceIndex(selectedTarget)
            if (indexCurrent !== currentIndex.current) {
                currentIndex.current = indexCurrent
            }
            
            isPrevious.current = prevTarget?.current && getServiceIndex(prevTarget?.current) > getServiceIndex(selectedTarget)
        }

        prevTarget.current = selectedTarget

        !isAdmin && (cameraControlsRef.current.mouseButtons.left = selectedTarget?.isHome ? 1 : null);
    }, [selectedTarget])

    useEffect(() => {
        if (isMobile && selectedTarget?.cameraMobile) {
            return setCameraPosition(selectedTarget?.cameraMobile)
        } else {
            setCameraPosition(selectedTarget?.camera)
        }
    }, [isMobile])

    useEffect(() => {
        if (isLoaded) {    
            setInitialCamera()
        }
    }, [isLoaded])

    const getServiceIndex = (selectedTarget) => {
        return services.findIndex(service => service.title === selectedTarget.title);
    }

    const handleScrollEnd = debounce(() => {
        if (isMenuOpen.current) return;

        cameraControlsRef.current.enabled = true;

        if (isExploreOpen || isContactOpen) return;

        //const currentScrollTop = scroll.range(0, 1);
        if (lastScroll.current < 0 /*currentScrollTop <= lastScroll.current*/) {
            if (selectedTarget?.isHome && currentIndex.current === 0) 
                currentIndex.current = 1;

            let index = currentIndex.current;
            index--;
            let service = services[index]
            !isMenuOpen.current && selectService(service, true)
            currentIndex.current = index
        } else {
            if (currentIndex.current === services.length -1)
                currentIndex.current = -1;
            let index = currentIndex.current;
            index++;
            let service = services[index]
            !isMenuOpen.current && selectService(service, true)
            currentIndex.current = index
        }

        setTimeout(() => {
            isScrolling.current = false
        }, 1000)
    }, 500);

    const handleWheel = (event) => {
        if (isMenuOpen.current) return;

        // console.log('event.deltaY', event.deltaY)
        if (isExploreOpen || isContactOpen) return;

        if (cameraControlsRef.current && cameraControlsRef.current.enabled && !isScrolling.current) {
            isScrolling.current = true
            // cameraControlsRef.current.enabled = false;
            const currentScrollTop = event.deltaY; // scroll.range(0, 1);
            lastScroll.current = currentScrollTop;
            handleScrollEnd();
        }
    };

    const touchStart = (event) => {
        if (isMenuOpen.current) return;

        if (isExploreOpen || isContactOpen) return;

        touchStartY.current = event.touches[0].clientY;
        touchEndY.current = event.touches[0].clientY;
        cameraControlsRef.current.enabled = true;
    }

    const touchMove = (event) => {
        if (isMenuOpen.current) return;

        if (isExploreOpen || isContactOpen) return;

        touchEndY.current = event.touches[0].clientY;
        cameraControlsRef.current.enabled = true;
    }

    const touchEnd = (event) => {
        if (isMenuOpen.current) return;

        if (isExploreOpen || isContactOpen) return;

        const distanceMoved = Math.abs(touchEndY.current - touchStartY.current);
        const THRESHOLD = 30;

        if (distanceMoved === NaN || distanceMoved < THRESHOLD) {
            cameraControlsRef.current.enabled = true;
            return
        }

        if (touchEndY.current > touchStartY.current) {
            let index = currentIndex.current;
            index = Math.max(0, index - 1);
            let service = services[index]
            !isMenuOpen.current && selectService(service, true)
            currentIndex.current = index
        } else {
            if (currentIndex.current === services.length - 1)
                currentIndex.current = -1;
            let index = currentIndex.current;
            index++;
            let service = services[index]
            !isMenuOpen.current && selectService(service, true)
            currentIndex.current = index
        }

        isScrolling.current = false
        cameraControlsRef.current.enabled = true;
    }
 
    const handleCameraTarget = async () => {
        if (prevTarget.current && prevTarget.current?.title !== 'Home')
            await moveCameraUp()

        if (isMobile && selectedTarget?.cameraMobile) {
            return setCameraPosition(selectedTarget?.cameraMobile)
        }

        setCameraPosition(selectedTarget?.camera)
    }

    const setInitialCamera = async () => {
        let position = [
            0.9080333308263351,
            8.057547435679567,
            65.8365809546937
        ], target = [
            -0.03368391739469856, 3.4695417384539873, 10.223449326512307
        ]

        cameraControlsRef.current.setLookAt(position[0], position[1], position[2], target[0], target[1], target[2], false);
        
        moveCameraToPosition({position: [
            0.01108499886359575,
            3.7529214234441963,
            14.328079216555029
        ], target: target}, props.setOverlayTitles)

        setTimeout(() => {
            props.setFadeLogoOut(true)
        }, 1000)
    }

    const moveCameraToPosition = async (step = cameraPosition, callback) => {
        if (!step?.position?.length || !isLoaded) return;

        let position = step?.position,
            target = step?.target;

        if (selectedTarget?.isHome) {
            position = [position[0] + origin[0], position[1] + origin[1], position[2] + origin[2]];
            target = [target[0] + origin[0], target[1] + origin[1], target[2] + origin[2]]
        }

        cameraControlsRef.current.smoothTime = 1.5;
        
        if (callback) {
            setTimeout(() => {
                callback(true);
            }, 1000)
        }

        if ((isPrevious.current && !selectedTarget?.isHome) || selectedTarget?.type === 'REALESTATE') {
            cameraControlsRef.current.smoothTime = .5;

            await cameraControlsRef.current.setLookAt(position[0], position[1] + 20, position[2], target[0], target[1], target[2], true);
            cameraControlsRef.current.smoothTime = .25;
        }

        await cameraControlsRef.current.setLookAt(position[0], position[1], position[2], target[0], target[1], target[2], true);
        
        if (callback) {
            /*await moveCameraToPosition({position: [
                0.01108499886359575,
                3.7529214234441963,
                14.328079216555029
            ], target})*/
           props.setIsStarted(true)
           // await moveCameraToPosition(services[0]?.camera)
        }
    }

    const moveCameraUp = async () => {
        cameraControlsRef.current.smoothTime = .2;
        let position = cameraPosition?.position,
            target = cameraPosition?.target;

        if (!position) return;

        await cameraControlsRef.current.setLookAt(position[0], position[1] + 20, position[2], target[0], target[1], target[2], true);
    }

    const handleCameraRestrictions = () => {
        if (selectedTarget?.isHome && !isAdmin) {
            return {
                maxPolarAngle: Math.PI / 2.2,
                minPolarAngle: Math.PI / 2.2,
                maxAzimuthAngle: Math.PI,
                minAzimuthAngle: Math.PI / 1.3
            }
        } else 
            return {
                maxPolarAngle: Infinity,
                minPolarAngle: -Infinity,
                maxAzimuthAngle: Infinity,
                minAzimuthAngle: -Infinity
            }
    }

    return <CameraControls ref={cameraControlsRef} enabled={isActive} enablePan={isAdmin} {...handleCameraRestrictions()}/>
}
