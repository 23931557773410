import { Canvas, useFrame, useThree } from '@react-three/fiber'
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { Effects } from './3D/Effects'
import { BakeShadows, Environment, ScrollControls, Sparkles, Sphere, Stars, Stats, useProgress } from '@react-three/drei'
import Controls from './3D/Controls'
import Overlay from './UI/Overlay'
import Titles3D from './3D/Titles3D'
import TWEEN from '@tweenjs/tween.js';
import LoadModel from './3D/LoadModel'
import LoadDrone from './3D/LoadDrone'
import CarouselModel from './3D/CarouselModel'
import throttle from 'lodash/throttle';
import FovHandler from './3D/FovHandler'
import Preload from './3D/Preload'
import ScrollLogic from './3D/ScrollLogic'

export default function DesertScene(props) {
    const origin = [0,1.2,3.5];//[-10, .8, 11.5]
    const [initialCameraPosition, setInitialCameraPosition] = useState([
        -0.5811779900257834 + origin[0],
        1.2916692179428086 + origin[1],
        8.003696430088311 + origin[2]
    ])

    const [initialCamera, setInitialCamera] = useState({
        position: [
            4.512119315365088,
            6.7718080636750795,
            22.760704476675546
        ],
        rotation: [
            -0.2247723965628688,
            0.19892692369330928,
            0.045151744953212135
        ]
    })

    const transformControlsRef = useRef();
    const [overlayTitles, setOverlayTitles] = useState();
    const [overlayLoader, setoverlayLoader] = useState(true);
    const { progress } = useProgress();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isReversed, setIsReversed] = useState(false);
    const [isNight, setIsNight] = useState(true);
    const [isLightOpen, setIsLightOpen] = useState(true);
    let isMounted = false;
    const godrayRef = useRef();
    const godrayRef2 = useRef();
    const godrayRef3 = useRef();
    const audioRef = useRef();
    const [isAudioPlaying, setisAudioPlaying] = useState(false)
    const [selectedTarget, setSelectTarget] = useState(false)
    const { services, menu } = props
    const [mouse, setMouse] = useState({ x: 0, y: 0 });
    const [currentIndex, setCurrentIndex] = useState(0);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [optimizePerformance, setOptimizePerformance] = useState(true);
    const isMobileRef = useRef(false);
    const [isTitleHovered, setIsTitleHovered] = useState(false);
    const [isExploreOpen, setIsExploreOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [fadeLogoOut, setFadeLogoOut] = useState(false);
    const [isStarted, setIsStarted] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        if (!isMounted) {
            isMounted = true;
            handleResize()

            animate();
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
       //services?.length && selectService(services[0])
    }, [services])

    useEffect(() => {
        if (transformControlsRef.current) {
            transformControlsRef.current.addEventListener("dragging-changed", () => {
                console.log([transformControlsRef.current.object.position?.x, transformControlsRef.current.object.position?.y, transformControlsRef.current.object.position?.z])
            })
        }
    }, [transformControlsRef])

    useEffect(() => {
        if (progress >= 100) {
            setIsLoaded(true)
        }
    }, [progress])

    useEffect(() => {
        if (isLoaded) {
            setTimeout(() => {
                setInitialCamera({
                    position: [
                        -0.5811779900257834  + origin[0],
                        1.2916692179428086  + origin[1],
                        8.003696430088311  + origin[2]
                    ]
                })
            }, 3000)
        }
    }, [isLoaded])

    const animate = () => {
        window.requestAnimationFrame(animate);
        TWEEN.update();
    }

    const handleResize = () => {
        if (window.innerWidth <= 456 && !isMobileRef.current) {
            isMobileRef.current = true
            setIsMobile(true)
        } else if (window.innerWidth > 456 && isMobileRef.current) {
            isMobileRef.current = false
            setIsMobile(false)
        }
    }

    useEffect(() => {
    }, [isMobile])

    useEffect(() => {
        services?.length && !selectedTarget && selectService(services[0])
    }, [isStarted])

    const setLights = () => {
        return (
            <>
                <hemisphereLight skyColor={isNight ? '#007FFF' : "#ffffff"} groundColor="#000000" intensity={isNight ? .05 : .2} />
                <directionalLight color={isNight ? '#007FFF' : '#ffffff'} castShadow intensity={isNight ? .5 : .2} position={[10, 10, -10]} />
            </>
        )
    }

    const renderSun = () => {
        return (
            <Sphere position={[500, 54.40739298093182, -220]} scale={2} >
                <meshPhysicalMaterial toneMapped={false} color={isNight ? "#F6F1D5" : '#feddb9'} emissive={isNight ? "#F6F1D5" : '#feddb9'} emissiveIntensity={60} transparent={true} />
            </Sphere>
        )
    }

    const renderHDR = () => {
        if (!isNight)
            return (
                <Environment files={"/moonlit_golf_1k.hdr"} resolution={200} near={1} far={1000} />
            )

        return (
            <>
                <Stars radius={100} depth={500} count={isMobile ? 20000 : 70000} factor={4} saturation={0} fade speed={1} />
                <spotLight
                    intensity={1}
                    position={[0 + origin[0], 5 + origin[1], 0 + origin[2]]}
                    angle={Math.PI / 4}
                    penumbra={1}
                    color="white"
                />
                <pointLight position={[0 + origin[0], 2 + origin[1], 5 + origin[2]]} intensity={ .15 } />
                {
                    isLightOpen && (!isMobile && !optimizePerformance) &&
                    <>
                        <Sparkles color={"#5b96f8"} count={500} scale={[2, 10, 1]} size={1} speed={0.4} position={[origin[0], origin[1], origin[2]]} />
                        <mesh position={[origin[0] + 0, origin[1] + 5, origin[2] + 0]} ref={godrayRef}>
                            <coneBufferGeometry args={[2, 17, 15]} />
                            <meshPhongMaterial transparent={true} opacity={.0002} toneMapped={false} color={"#fad264"} emissive="#fad264" emissiveIntensity={20} />
                        </mesh>
                        <mesh position={[origin[0] + 0, origin[1] + 5, origin[2] + 0]} ref={godrayRef3}>
                            <coneBufferGeometry args={[1, 7, 15]} />
                            <meshPhongMaterial transparent={true} opacity={.0001} toneMapped={false} color={"#fad264"} emissive="#fad264" emissiveIntensity={20} />
                        </mesh>
                    </>
                }
            </>
        )
    }

    const renderSection = (service) => {
        return (
            <>
                {
                    selectedTarget?.path === service?.path &&
                    <LoadModel scale={service?.scale || [2, 2, 2]} position={service?.position} castShadow={service?.castShadow} path={service?.path} rotation={service?.rotation} isMovable={service?.isMovable} selectedTarget={selectedTarget} fromSection={true} service={service} />
                }
                {
                    (!isMobile || selectedTarget?.path === service?.path) && service?.position &&
                    <pointLight color={'#fff'} intensity={.1} position={[service?.camera?.position[0], service?.camera?.position[1], service?.camera?.position[2]]} />
                }
            </>
        )
    }

    const renderTitles3D = () => {
        if (!services?.length) return;

        return services.map(service => {
            return (
                <>
                    {
                        service?.title3D && !isMobile && (selectedTarget?.isHome || !isStarted) && 
                        <Titles3D title={service?.title3D} selectService={selectService} service={service} setIsTitleHovered={setIsTitleHovered} isMobile={isMobile}/>
                    }

                    {
                        service?.path &&
                        <Preload path={service?.path}/>
                    }

                    {
                        service?.path &&
                        renderSection(service)
                    }
                </>
            )
        })
    }

    const selectService = (service, isFromNext) => {
        setSelectTarget(undefined)
        if (!service) {
            service = services[0]
        }

        setSelectTarget({ ...service, isFromNext })
    }

    const handleScroll = useCallback(throttle(() => {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollTop > lastScrollTop) {
            selectService(currentIndex++)
            setCurrentIndex(currentIndex++);
        } else if (currentScrollTop < lastScrollTop) {
            selectService(currentIndex--)
            setCurrentIndex(currentIndex--)
        }
        setLastScrollTop(currentScrollTop);
    }, 200), [lastScrollTop]);

    useEffect(() => {
    }, [isExploreOpen])

    return (
        <>
            <Overlay
                overlayTitles={overlayTitles}
                renderLoader={overlayLoader}
                isLoaded={isLoaded}
                selectedTarget={selectedTarget}
                selectService={selectService}
                services={services}
                menu={menu}
                setIsExploreOpen={setIsExploreOpen}
                setIsContactOpen={setIsContactOpen}
                isContactOpen={isContactOpen}
                isExploreOpen={isExploreOpen}
                fadeLogoOut={fadeLogoOut}
                progress={progress}
            />
            <div className='filter' />
            <Canvas
                colorManagement
                shadows={!optimizePerformance && !isMobile}
                gl={{ logarithmicDepthBuffer: true, antialias: true, alpha: true }}
                dpr={1}
                camera={{ position: [...initialCameraPosition], fov: 20, far: 2500 }}
                performance={{ min: .75 }}
                style={{ width: '100%', height: '100%', cursor: selectedTarget?.isHome && isLoaded ? (isTitleHovered ? 'url(/crosshairFocus.png) 20 20,auto' : 'url(/crosshair.png) 20 20,auto') : 'auto' }}
            >
                <Suspense>
                    <BakeShadows />
                    <FovHandler />
                    <LoadModel position={[
                        82.9215714332035,
                        -69.41894466709567,
                        -102.77264606331883
                    ]} isMovable={false} scale={[8, 8, 8]} path={'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/dunes_small.glb'} isV2={true} optimize={true} castShadow={true} isDunes={true} />

                    <color attach="background" args={[isNight ? "#1e2325" : '#d8e5ef']} />
                    {renderHDR()}
                    {setLights()}
                    {renderSun()}

                    <Controls
                        initialCameraPosition={initialCamera.position}
                        initialCameraRotation={initialCamera.rotation}
                        setOverlayTitles={setOverlayTitles}
                        setIsReversed={setIsReversed}
                        selectedTarget={selectedTarget}
                        isMobile={isMobile}
                        selectService={selectService}
                        services={services}
                        isContactOpen={isContactOpen}
                        isExploreOpen={isExploreOpen}
                        origin={origin}
                        isLoaded={isLoaded}
                        setFadeLogoOut={setFadeLogoOut}
                        setIsStarted={setIsStarted}
                        isStarted={isStarted}
                    />

                    {false && <Stats showPanel={0} className="stats" />}
                    <LoadModel position={origin} path={'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/cape.glb'} optimize={true} isStarted={isStarted}/>

                    <LoadModel position={origin} path={'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/DTK2.glb' || '/compressed (2).glb'} isReversed={isReversed} castShadow={true} optimize={true} isKnight={true} isStarted={isStarted}/>
                    <LoadModel position={origin} path={'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/DTH.glb' || 'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/Desrt+knight+optimized+animated+(2).glb' || '/compressed (2).glb'} isReversed={isReversed} castShadow={true} optimize={true} isHorse={true}/>
                    <LoadDrone
                        scale={[.008, .008, .008] || [.0025, .0025, .0025]}
                        position={[1, 3, 0]}
                        path={'https://slidzo.s3.eu-central-1.amazonaws.com/DesertKnight/drone_compressed.glb' || "https://slidzo.s3.eu-central-1.amazonaws.com/DK/sci-fi_camera_drone.glb"}
                        selectedTarget={selectedTarget}
                        godrayRef={godrayRef2}
                        isMobile={isMobile}
                        optimizePerformance={optimizePerformance}
                        origin={origin}
                    />
                    {renderTitles3D()}
                    {true && <Effects bloomValue={.5} godrayRef={godrayRef} godrayRef3={godrayRef3} godrayRef2={godrayRef2} isMobile={isMobile} optimizePerformance={optimizePerformance}/>}
                    {true && <fog attach="fog" args={[isNight ? "#1e2325" : '#d8e5ef', 300, 500]} />}
                </Suspense>
            </Canvas>
        </>
    )
}