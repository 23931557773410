import React, { useRef, useState } from 'react'
import { useEffect, useLayoutEffect, useMemo } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { TextureLoader } from 'three';
import { applyProps, useFrame, useLoader, useThree } from '@react-three/fiber'
import TWEEN from '@tweenjs/tween.js';

export default function CarouselModel(props) {
    return;
    let path = '/models/cards.glb';
    const { scene, nodes, materials } = useGLTF(path)
    const {isSelected} = props;
    const image1 = useLoader(TextureLoader, '/images/VR_01.jpg');
    const image2 = useLoader(TextureLoader, '/images/VR_02.jpg');
    const image3 = useLoader(TextureLoader, '/images/VR_03.jpg');
    const image4 = useLoader(TextureLoader, '/images/VR_04.jpg');
    const image5 = useLoader(TextureLoader, '/images/VR_05.jpg');
    const ref = useRef();
    // const rotationValue = useRef(0);
    const [rotationValue, setRotationValue] = useState(0)
    const isFadedOut = useRef(false);
    const [hoveredMesh, setHoveredMesh] = useState(null);

    useEffect(() => {
        rotationAnimation(0)

        if (materials['diffuse_0.001']) {
            applyProps(materials['diffuse_0.001'], { side: THREE.DoubleSide, color: 'white', transparent: true, opacity: 1 })
        }

        setCardImage(nodes['object_1'], image1)
        setCardImage(nodes['object_2'], image2)
        setCardImage(nodes['object_3'], image3)
        setCardImage(nodes['object_4'], image4)
        setCardImage(nodes['object_5'], image5)
    }, [scene, nodes, materials])

    useFrame(() => {
        if (hoveredMesh) {
            hoveredMesh.rotation.y += 0.02;

            const scaleFactor = 1.1; 
            hoveredMesh.scale.set(scaleFactor, scaleFactor, scaleFactor);
        } else {
            Object.values(nodes).forEach((node) => {
                node.scale.set(1, 1, 1); 
                node.rotation.y = 0; 
            });
        }
    })

    const rotationAnimation = (intialRotation) => {
        let initial = {
            rotation: intialRotation
        }, target = {
            rotation: intialRotation + Math.PI
        }
        let tween = new TWEEN.Tween(initial).to(target, 80000).delay(0).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
            setRotationValue(initial.rotation)
        }).onComplete(() => {
            rotationAnimation(intialRotation + Math.PI)
        }).start();
    }

    const setCardImage = (node, imageTexture) => {
        if (node) {
            const materialName = node.material.name;
            const material = materials[materialName];
            const clonedMaterial = material.clone();

            const textureAspect = imageTexture.image.width / imageTexture.image.height;
            const meshAspect = 3 / 4;
            imageTexture.wrapS = THREE.RepeatWrapping;
            imageTexture.repeat.x = meshAspect / textureAspect;

            imageTexture.offset.x = -0.5 * ((meshAspect / textureAspect) - 1);

            clonedMaterial.map = imageTexture;
            node.material = clonedMaterial;
            node.userData = imageTexture
            console.log(node)
        }
    }

    const hoverCard = (event) => {
        setHoveredMesh(event.object)
    }
 
    const fadeInOut = (isFadeOut) => {
        if (isFadedOut.current && isFadeOut) return;

        let target = {
            opacity: isFadeOut ? 0 : 1
        }

        let initial = {
            opacity: !isFadeOut ? 0 : 1
        }

        let tween = new TWEEN.Tween(initial).to(target, 1500).delay(0).easing(TWEEN.Easing.Cubic.InOut).onUpdate(() => {
            scene.traverse(child => {
                if (child.isMesh) {
                    child.material.transparent = true;
                    child.material.opacity = initial.opacity;
                }
            });
        }).onComplete(() => {
            isFadedOut.current = isFadeOut
        }).start();
    }

    useEffect(() => {
        fadeInOut(!isSelected)
    }, [isSelected])

    return (
        <mesh ref={ref}>
            <primitive object={scene} {...props} rotation={[0, rotationValue, 0]}>
                {Object.values(nodes).map((node, index) => (
                    <primitive
                        key={index}
                        object={node}
                        onPointerOver={hoverCard}
                    />
                ))}
            </primitive>
        </mesh>
    )
}
