import { Html, Text, TransformControls } from '@react-three/drei'
import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

export default function Titles3D(props) {
    const textRef = useRef();
    const textRef2 = useRef();

    const controlsRef = useRef();
    const [lightIntensity, setLight] = useState(0);
    const [hovered, setHover] = useState(false);

    useEffect(() => {
        let material = new THREE.MeshStandardMaterial({
            transparent: true,
            opacity: .1,
            toneMapped: true,
            emissive: '#fad264',
            emissiveIntensity: 10
        })

        if (textRef.current) {
            textRef.current.material = material
            textRef2.current.material = material
        }
        
        if (controlsRef.current)
            controlsRef.current.addEventListener("dragging-changed", () => {
                console.log([controlsRef.current.object.position?.x, controlsRef.current.object.position?.y, controlsRef.current.object.position?.z])
            })
    }, [])

    useEffect(() => {
        if (textRef.current?.material && !props.isControllable) {
            let target = {
                opacity: hovered ? .8 : .2,
                emissiveIntensity: hovered ? 10 : 0,
                lightIntensity: hovered ? .2 : 0
            }

            let initial = {
                opacity: !hovered ? .8 : .2,
                emissiveIntensity: !hovered ? 10 : 0,
                lightIntensity: !hovered ? .2 : 0
            }

            let tween = new TWEEN.Tween(initial).to(target, 500).delay(0).easing(TWEEN.Easing.Cubic.InOut).onUpdate(() => {
                if (!textRef.current?.material) return;

                textRef.current.material.toneMapped = false
                textRef.current.material.opacity = initial.opacity;
                textRef.current.material.emissiveIntensity = initial.emissiveIntensity;
                setLight(initial.lightIntensity);
                textRef2.current.material = textRef.current.material
            }).onComplete(() => {

            }).start();
        }

        document.body.style.cursor = hovered ? 'pointer' : 'auto'
        props.setIsTitleHovered(hovered)
    }, [hovered])

    if (props.isControllable)
        return (
            <TransformControls
                ref={controlsRef}
                position={props.title?.position || [0, 0, 0]}
            >
                <Text
                    ref={textRef}
                    color={"white"}
                    font={"/fonts/Ailerons.otf"}
                    anchorX="center"
                    anchorY="middle"
                    fontSize={50}
                    fontWeight={700}
                    rotation={[0, -Math.PI, 0]}
                >
                    {props.title?.value}
                </Text>
            </TransformControls>
        )

    const renderTitles = () => {
        if (props.isMobile) return;

        return (
            <>
            <Text
                    ref={textRef2}
                    color={"white"}
                    font={"/fonts/Play-Bold.ttf"}
                    anchorX="center"
                    anchorY="middle"
                    fontSize={1.2}
                    fontWeight={700}
                    rotation={[0, -Math.PI, 0]}
                    position={[props.title?.position[0], props.title?.position[1] + 4, props.title?.position[2]] || [0, 0, 0]}
                    onPointerOver={(e) => {
                        e.stopPropagation();
                        setHover(true);
                    }}
                    onPointerOut={(e) => {
                        e.stopPropagation();
                        setHover(false);
                    }}
                    onClick={() => props.selectService(props.service)}
                >
                    SERVICES
                </Text>
                <Text
                    ref={textRef}
                    color={"white"}
                    font={"/fonts/Gudea-Bold.ttf"}
                    anchorX="center"
                    anchorY="middle"
                    fontSize={5}
                    fontWeight={800}
                    rotation={[0, -Math.PI, 0]}
                    position={props.title?.position || [0, 0, 0]}
                    onPointerOver={(e) => {
                        e.stopPropagation();
                        setHover(true);
                    }}
                    onPointerOut={(e) => {
                        e.stopPropagation();
                        setHover(false);
                    }}
                    onClick={() => props.selectService(props.service)}
                    >
                    {props.title?.value}
                </Text>
            </>
        )
    }

    if (props.title?.position)
        return (
            <>
                {renderTitles()}
                {
                    <spotLight angle={Math.PI} penumbra={0.9} intensity={(props.isMobile ? .05 : lightIntensity) || 0} color={'#fad264'}
                        position={props.title?.position || [0, 0, 0]}
                    />
                }
            </>
        )
}
