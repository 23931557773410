import React, { useEffect, useState } from 'react'

export default function Contact(props) {
    const renderScroll = () => {
        return (
            <>
                <div className='close right' onClick={() => props.setIsContactOpen(false)}>
                    <i/> Close <p>|</p> <span>Esc</span>
                </div>
            </>
        )
    }

    return (
        <div className='explore_more'>
            <div className='exp_overlay' />
            <div className='options contact'>
                {renderScroll()}
            </div>
            <div className='exp_content_scroll'>
                <div className='exp_content'>
                <div className='contact_us_container'>
                    <div className='contact_us'>
                        <div className='contact_card'>
                            <div className='card_header'>
                                <h3>Get in touch</h3>
                                <h5>You can reach us anytime</h5>
                            </div>

                            <form noValidate autoComplete='off'>
                                <div className='input-container'>
                                    <label>Business Name</label>
                                    <input type="text" placeholder="Your business name" name="businessName"></input>
                                </div>

                                <div className='input-container'>
                                    <label>Email*</label>
                                    <input type="email" placeholder="Your contact email" name="email"></input>
                                </div>

                                <div className='input-container'>
                                    <label>How Can We Help?</label>
                                    <textarea
                                        placeholder="Tell us a little about the project..."
                                        name="message"
                                        rows="8"
                                    ></textarea>
                                </div>
                                <div className='details-button'>
                                    Send Message
                                </div>
                            </form>
                        </div>
                        <div className='text_container'>
                            <h3>
                                Contact us
                            </h3>
                            <h4>
                                We want to help you with your Metaverse business
                            </h4>
                            <p>
                                Your business needs to implement amazing Metaverse solutions? We're here to help! Get in touch with our team, send us your details and let's collaborate on your amazing projects.
                            </p>
                            <div className='contact_info'>
                                <h3>Address</h3>
                                <p>
                                    Office 7 - Burj Al Salam - 36-01 Sheikh Zayed Rd - Trade Centre - Trade Centre 1 - Dubai - United Arab Emirates
                                </p>
                            </div>

                            {/*<div className='contact_info'>
                                <h3>Phone</h3>
                                <p>-</p>
                            </div>*/}

                            <div className='contact_info'>
                                <h3>Email</h3>
                                <p>info@desertknight-studios.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
